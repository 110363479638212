import React from 'react'
import { selectThemeColors } from '@utils'
import { ThemeContext } from '@src/utility/context/ThemeColors'

const FallbackSpinner = () => {
  return (
    <div className='fallback-spinner'>
      <div className='loading'>
        <div style={{ borderLeft: `3px solid ${selectThemeColors(ThemeContext).colors.primary}` }} className='effect-1 effects'></div>
        <div style={{ borderLeft: `3px solid ${selectThemeColors(ThemeContext).colors.primary}` }} className='effect-2 effects'></div>
        <div style={{ borderLeft: `3px solid ${selectThemeColors(ThemeContext).colors.primary}` }} className='effect-3 effects'></div>
      </div>
    </div>
  )
}

export default FallbackSpinner