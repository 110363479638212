// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

import { Routes } from '../router/routes'

const initialDashboard = () => {
    const apps = Routes[0]?.iframeConfigs?.map((app) => {
        return {
            id: app.id,
            isLoaded: false
        }
    })

    return apps
}

export const appsSlice = createSlice({
  name: 'apps',
  initialState: {
    apps: initialDashboard()
  },
  reducers: {
    handleApps: (state, action) => {
        state.apps = action.payload
    }
  }
})

export const { handleApps } = appsSlice.actions

export default appsSlice.reducer
